<script>
  import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
 import { useStore } from 'vuex'
import serverConfig   from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';
import { ref } from "vue";
import { useCookies } from "vue3-cookies";
import { mapGetters} from "vuex";
import { computed } from 'vue'

const moment = require('moment')

export default {
  name: "ordersTable",
  props: [
    'data',
    'type',
    'title',
  ],
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    const { cookies } = useCookies();
    const store = useStore();

    let tk = 'Bearer '+ localStorage.getItem('tk')
    let gettersSettingRole = computed(() => store.getters["settings/getSettingsRole"])
    let gettersSettingArea = computed(() => store.getters["settings/getSettingsArea"])

    return { active, toggleActive,tk,cookies, v$: useVuelidate(), gettersSettingRole, gettersSettingArea};
  },
  data() {
	return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        page: 1,
        perPage: 100,
        sortRes:'asc',
        value: 'All',
        pages: [],
        paginated:{},
        select:'all',
        search:'',
        responsesList:[],
        n_results:0,
        dataEdit:{},
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        inviteNUser:{
          email:"",
          message:"",
          status:"",
          role:""
        },
        customer:{
          status:'init',
          code:'',
          sigla:'',
          tipoSoggetto:'',
          ragSoc:'aggiungi `Ragione sociale`',
          codiceFiscale:'aggiungi `Codice Fiscale`',
          partitaIva:'aggiungi `Partita Iva`',
          codiceAteco:'aggiungi `Codice Ateco`',
          nRea:'',
          artigiano:'No',
          email:'',
          telefono:'aggiungi `Telefono`',
          indirizzo:'aggiungi `Ragione sociale`',
          regimeFiscale:'ordinario',
          regimeContabile:'ordinario',

        },
        items: [{
            text: "Gestione utenti",
            href: "/",
          },
          {
            text: "Lista utenti",
            active: true,
          },
        ],
        }
	},
    validations: {
      inviteNUser: {
        email: {
          required: helpers.withMessage("Email obbligatoria", required),
          email: helpers.withMessage("Per favore, inserisci una email valida", email),
        },
        status: {
          required: helpers.withMessage("Stato obbligatorio", required),
        },
        role: {
          required: helpers.withMessage("Ruolo obbligatorio", required),
        },
      },
    },
	computed:{ 
        ...mapGetters('settings', ['getSettingsRole','getSettingsArea']),
        notification() {
            return this.$store ? this.$store.state.notification : null;
        },
        displayedPosts() {
            return this.paginate(this.responsesList);
        },
	},
  beforeMount() {
      axios.interceptors.response.use(
        (response) => {
            
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
      axios.get(`${serverConfig.EP}users/acl`, 
        { 
          headers: { 'authorization':this.tk},
          params: { 
            page: this.$router.currentRoute._rawValue.name,
          },
        },

      ).then(response => {
          if (response.data){
            if (response.data.user){
              if (response.data.user.roles_permissions.layout) {
                this.changeLayoutType({layoutType:response.data.user.roles_permissions.layout})
                
              }
            }
          }
      });
    },
    mounted() {
      this.getList();
    },
	methods:{
    axiosInterceptor(){
        axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
      },
      convertIsoToLocal(date){     
        if (date) {
          let momentNow = moment(date).locale("it")
          return  momentNow.format('DD/MM/YYYY') +' '+ momentNow.hours() +':'+ momentNow.minutes()
        }
      },
      formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.',',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      roundTo(value, decimalpositions)
      {
        var i = value * Math.pow(10,decimalpositions);
        i = Math.round(i);
        return i / Math.pow(10,decimalpositions);
      },
      replName(val,el){
        if (val.includes(el)){
          return val.replaceAll(el,' ')
        } else {
          return val
        }
      },
      formatKb(val){
        if (val){
          return this.roundTo(val / 1024, 1)
        } else {
          return ''
        }
      },
      getParent(){
        this.getList()
      },
      resetQuery(){

        this.getList()
      },
    getList(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}carts/orders`, 
        { 
          params: { 
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            search: this.search,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
        console.log('RESP')
        console.log(response.data)
        this.responsesList = response.data.results.data;
        this.paginated = response.data.results;
        this.n_results = response.data.results.total;

        })
    },

 
    paginateAct(pg){
        this.page = pg
        this.getList()
    },
   
    
  },
  components: {

  },
};
</script>
<template>
  <div class="back-grey">
    <div class="card">
            <div class="card-header border-0">
              <div class="row g-4">
                <div class="col-sm-auto">
                  <div>

                  </div>
                </div>
                <div class="col-lg-10 col-md-12">
                  <div class="">
                    <div class="search-box ms-2">
                      <input type="text" class="form-control" placeholder="Cerca ordine..." v-model="search" @input="resetQuery()" />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>

            <div class="card-header  mb-50">
              <div class="row align-items-center mb-50">
                <div class="col ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Tutti
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">

                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
        <div class="card-body">
          <div class="table-card mb-1">
                <div class="row thRow">
                 
                    <div class="col-lg-1 col-md-12">
                       Numero
                    </div>
                    <div class="col-lg-2 col-md-12">
                       Cliente
                    </div>
                    <div class="col-lg-1 col-md-12">
                      Tipologia ordine
                    </div>

                    <div class="col-lg-1 col-md-12">
                       Data creazione
                    </div>
                    <div class="col-lg-1 col-md-12">
                        Stato
                    </div>
                    <div class="col-lg-1 col-md-12 text-right">
                        Azioni
                    </div>
                </div>
                <div class="row list tRow form-check-all d-flex" v-for="(item, index) of responsesList" :key="index">
                    <div class="col-lg-1 col-md-12 ">
                        <div class="">
                          <template v-if="item.orderproc.length>0">
                            <strong>{{ item.orderproc[0].orderCode }}</strong>
                          </template>
                          <template v-else>
                            <template v-if="item.orderNumber">
                              <strong>{{ item.orderNumber }}/{{ item.year }}</strong>
                            </template>
                            <template v-else>
                              N.D.
                            </template>
                          </template>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                      <template v-if="item.project.length>0">
                        project
                      </template>
                      <template v-else>
                        NO
                      </template>
                      <!--
                      
                        <template v-if="item.project.length>0">
                          <div class="list-inline hstack gap-2 mb-0" v-if="item.project[0].customerDetails">
                           
                            <strong v-if="item.project[0].customerDetails[0].nome">{{ item.project[0].customerDetails[0].nome }}</strong>
                            <strong v-if="item.project[0].customerDetails[0].cognome">  {{ item.project[0].customerDetails[0].cognome }}</strong>

                            <template v-if="item.project[0].customerDetails.TipoSoggetto=='3' || item.project[0].customerDetails.TipoSoggetto==3 ">
                             <strong v-if="item.project[0].customerDetails[0].ragSoc.length>0"><br>{{ item.project[0].customerDetails[0].ragSoc }}</strong>
                            </template>
                            <template v-else>
                              <strong v-if="item.project[0].customerDetails[0].ragSoc.length>0"><br>{{ item.project[0].customerDetails[0].ragSoc }}</strong>
                            </template>
                          </div>
                        </template>
                        <template v-else>
                          <div class="list-inline hstack gap-2 mb-0" v-if="item.user.length>0">
                            {{ item.user[0].firstName }} {{ item.user[0].lastName }}
                            <template v-if="item.user[0].ragSoc.length>0">
                            <br> {{ item.user[0].ragSoc }}
                            </template>
                          </div>
                        </template>
                        -->
                    </div>
                    <div class="col-lg-1 col-md-12 " >
                        <div class="">
                          <template v-if="item.orderproc.length>0">
                            <template v-if="item.orderproc[0].canalePerUtente=='mov-magazzino'">
                              Mov. cantiere
                            </template>
                            <template>
                              Preventivo/Ordine
                            </template>
                          </template>
                        </div>
                    </div>

                    <div class="col-lg-1 col-md-12">
                        <div class="">    
                             {{ convertIsoToLocal(item.createdDate) }}
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-12">
                      <template v-if="item.status=='completed'">
                        <span class="badge text-uppercase badge-soft-success">
                          Ordinato
                        </span>
                      </template>
                      <template v-else-if="item.status=='processing'">
                        <span class="badge text-uppercase badge-soft-info">
                          In Lavorazione
                        </span>
                      </template>
                      <template v-else-if="item.status=='ordered'">
                        <span class="badge text-uppercase badge-soft-success">
                          Ordine in ERP
                        </span>
                      </template>
                      <template v-else-if="item.status=='quotation'">
                        <span class="badge text-uppercase badge-soft-info">Preventivo</span>
                      </template>
                      <template v-else>
                        <span class="badge text-uppercase badge-soft-danger">Sospeso</span>
                      </template>
                       
                    </div>
                    <div class="col-lg-1 col-md-12">
                        <ul class="list-inline ">
                            <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Dettaglio ordine">
                                <router-link
                                    :to="'/ecommerce/order/'+item.orx+'/details'"
                                    class="btn btn-info btn-sm"
                                ><i class="ri-pencil-fill fs-16"></i>
                                Dettagli</router-link>
                                
                            </li>
                        </ul>
                    </div>
                    

                </div>
          </div>

            <div>
              <div class="table-responsive table-card mb-1">

                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex tRow justify-content-end mt-3">        
                    <div class="col-lg-3 col-md-12 ">
                        <label class="pt-2">Risultati per pagina:</label>
                        <div class="pl-10 pr-20">
                        
                        <select class="form-control" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                        <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>

              </div>
            </div>

          </div>
        </div>
  </div>
</template>